import { dayjs } from '../lib/dayjs.lib';
import { ISOString } from '../lib/iso-string.type';

export type TCheckIncludeCurrentTimeOption = {
  from?: ISOString;
  to?: ISOString;
  dayOfWeeks?: number[];
  dayOfMonth?: number[];
  monthOfYear?: number[];
  years?: number[];
};

export function checkIncludeCurrentTime(option: TCheckIncludeCurrentTimeOption) {
  const { from, to, dayOfWeeks, dayOfMonth, monthOfYear, years } = option;
  const diffDays = Math.max(0, dayjs(to).tz().diff(from, 'day'));

  const fromTime = from
    ? dayjs().tz().set('hour', dayjs(from).tz().hour()).set('minute', dayjs(from).tz().minute()).set('second', dayjs(from).tz().second())
    : dayjs().tz().startOf('date');

  const toTime = to
    ? dayjs()
        .tz()
        .add(diffDays, 'day')
        .set('hour', dayjs(to).tz().hour())
        .set('minute', dayjs(to).tz().minute())
        .set('second', dayjs(to).tz().second())
    : dayjs().tz().add(diffDays, 'day').endOf('date');

  return (
    (!years?.length || years.includes(dayjs().tz().year())) &&
    (!monthOfYear?.length || monthOfYear.some(month => fromTime.month() === month)) &&
    (!dayOfMonth?.length || dayOfMonth.some(day => fromTime.date() === day)) &&
    (!dayOfWeeks?.length || dayOfWeeks.some(day => fromTime.day() === day)) &&
    dayjs().tz().isBetween(fromTime, toTime, 'second', '[]')
  );
}
