import { Duration, DurationUnitType } from 'dayjs/plugin/duration';
import { useEffect, useMemo, useRef, useState } from 'react';

import { useAnimationFrame } from '@totopkg/shared-util-animation-frame';

import { dayjs } from '../lib/dayjs.lib';
import { ISOString } from '../lib/iso-string.type';

const durationFormatCode: Record<string, dayjs.ManipulateType> = {
  Y: 'year',
  M: 'month',
  D: 'day',
  H: 'hour',
  m: 'minute',
  s: 'second'
};

function compareDuration(a: Duration, b: Duration, unit?: DurationUnitType): number {
  return dayjs.utc(a.asMilliseconds()).diff(dayjs.utc(b.asMilliseconds()), unit);
}

export const useFrameCountDown = (
  endTime: ISOString | undefined,
  options?: {
    formatter?: string;
    onCountDownEnd?: () => void;
    unit?: DurationUnitType;
  }
) => {
  const { formatter = 'HH:mm:ss', unit = 'second' } = options || {};

  const frameCountdownRequestId = useRef(`${useFrameCountDown.name}-${Math.random()}`).current;

  const endTimeRef = useRef<ISOString>();

  const [duration, setDuration] = useState<Duration>();

  const formattedCountdown = useMemo(() => {
    if (!duration?.asMilliseconds()) {
      const regex = new RegExp(Object.keys(durationFormatCode).join('|'), 'gi');
      return formatter.replace(regex, '0');
    }

    let _formatted = formatter;

    Object.keys(durationFormatCode).forEach(key => {
      const regexMulti = new RegExp(`${key}+`, 'g');
      const regex = new RegExp(key, 'g');

      _formatted = _formatted
        .slice()
        .replace(regexMulti, key)
        .replace(
          regex,
          `0000${Math.floor(duration?.get(durationFormatCode[key]))}`.slice(-1 * _formatted.split('').filter(c => c === key).length)
        );
    });

    return _formatted;
  }, [duration, formatter]);

  const { start } = useAnimationFrame({
    id: frameCountdownRequestId,
    functionRef: () => {
      if (!endTimeRef.current) return;

      let _remainDuration = dayjs.duration(dayjs(endTimeRef.current).startOf(unit).diff(dayjs().startOf(unit), unit), unit);

      if (_remainDuration.as('millisecond') < 0) {
        _remainDuration = dayjs.duration(0);
      }

      setDuration(prev => (!prev || compareDuration(prev, _remainDuration, unit) !== 0 ? _remainDuration : prev));

      if (_remainDuration.asMilliseconds() <= 0) {
        options?.onCountDownEnd?.();
      }
    },
    deltaTime: dayjs().add(1, unit).diff(dayjs())
  });

  useEffect(() => {
    endTimeRef.current = endTime;
  }, [endTime]);

  useEffect(() => {
    start();
  }, [start]);

  return {
    duration,
    formattedCountdown
  };
};
