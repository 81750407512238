import { useCallback, useEffect, useRef, useState } from 'react';

import { useAnimationFrame } from '@totopkg/shared-util-animation-frame';

import { checkIncludeCurrentTime, TCheckIncludeCurrentTimeOption } from '../util/check-include-current-time.util';

/** disabled interval check if  */
export const useCheckIncludeCurrentTime = (times: TCheckIncludeCurrentTimeOption[], intervalTime?: number) => {
  const requestAnimationId = useRef(`${useCheckIncludeCurrentTime.name}-${Math.random()}`).current;

  const [isIncludeCurrentTime, setIsIncludeCurrentTime] = useState<boolean>(false);

  const checkIsOpen = useCallback(() => {
    setIsIncludeCurrentTime(times?.some(time => checkIncludeCurrentTime(time)));
  }, [JSON.stringify(times)]);

  const { start, pause, clear } = useAnimationFrame({
    id: requestAnimationId,
    functionRef: checkIsOpen,
    deltaTime: intervalTime
  });

  useEffect(() => {
    if (intervalTime && intervalTime > 0) {
      start();
    } else {
      checkIsOpen();
    }
  }, [checkIsOpen, intervalTime, requestAnimationId, start]);

  return { isIncludeCurrentTime, start, pause, clear };
};
